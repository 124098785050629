import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  useModal
} from '@/components/ui/animated-modal'
import { Loader2, Send, X } from 'lucide-react'
import TextShimmer from '../ui/text-shimmer'
import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { Textarea } from '../ui/textarea'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '../ui/form'
import { useState } from 'react'
import { toast } from 'sonner'

const formSchema = z.object({
  name: z.string().min(3, { message: 'Add meg a neved' }),
  email: z.string().email({ message: 'Hibás email cím' }),
  phone: z.string().optional(),
  note: z.string().min(10, { message: 'Írd be az üzeneted' })
})

export default function ContactUsModal(props: any) {
  const [loading, setLoading] = useState(false)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      note: ''
    }
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setLoading(true)

    async function sendValues() {
      return await fetch('https://api.leoai.hu/send-contact-us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      }).then(res => res.json())
    }

    toast.promise(sendValues(), {
      loading: 'Küldés folyamatban...',
      success: 'Sikeresen elküldve!',
      error: 'Valami hiba történt! Próbáld újra később.'
    })

    setLoading(false)

    const closeButton = document.getElementById('modal-close-button')
    closeButton?.click()
  }

  return (
    <Modal>
      {props.children}

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalBody>
            <ModalContent className="py-[18px] px-6">
              <div>
                <TextShimmer className="text-neutral-700 font-bold text-left ml-0 mb-2 mt-1 text-lg md:text-2xl">
                  Kapcsolatfelvétel
                </TextShimmer>

                <p className="text-slate-700 text-left font-normal mb-4 mt-2 text-sm">
                  <b>24 órán belül</b> válaszolunk a megadott elérhetőségek
                  egyikén.
                </p>
              </div>
              <div className="space-y-4 text-left">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Név: *</FormLabel>
                      <FormControl>
                        <Input placeholder="Gipsz Jakab" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email címed: *</FormLabel>
                      <FormControl>
                        <Input placeholder="pelda@gmail.com" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Telefonszám:</FormLabel>
                      <FormControl>
                        <Input placeholder="+36 20 123 4567" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="note"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Üzeneted: *</FormLabel>
                      <FormControl>
                        <Textarea placeholder="Üzeneted..." {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </ModalContent>
            <ModalFooter className="gap-4">
              <CloseButton />

              <Button disabled={loading} type="submit" className="w-full">
                Küldés
                {loading ? (
                  <Loader2 size={20} className="ml-2 animate-spin" />
                ) : (
                  <Send size={20} className="ml-2" />
                )}
              </Button>
            </ModalFooter>
          </ModalBody>
        </form>
      </Form>
    </Modal>
  )
}

function CloseButton() {
  const { setOpen } = useModal()

  return (
    <Button
      type="button"
      id="modal-close-button"
      onClick={() => setOpen(false)}
      variant="outline"
      className="w-full">
      Mégsem <X size={20} className="ml-2" />
    </Button>
  )
}
